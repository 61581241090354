<template>
  <div class="padding-top-layout mr-md-40-px">
    <page-title
      class="padding-left-layout mb-20-px mt-header-title"
      :title="$t('product_dashboard_main.title_dashboard')"
    />
    <div class="dashboard-home">
      <div class="dashboard-filter">
        <dashboard-filter @update="handlerUpdateBranchIds" />
      </div>
      <div class="emission-by-period">
        <products-by-period
          class="product-period"
          v-if="isLoading"
          :durationList="durationList"
          :branchIds="branchIds"
          :productList="getProductListByBranch"
          :isHasBranchNull="isHasBranchNull"
        />
      </div>
      <div class="emission-by-category">
        <products-with-high-emissions
          class="products-high-emissions"
          v-if="isLoading"
          :durationList="durationList"
          :yearList="yearList"
          :branchIds="branchIds"
          :isHasBranchNull="isHasBranchNull"
          :startMonthProps="startMonth"
        />
      </div>
      <div class="emission-by-category emission-by-bubble">
        <products-by-bubble
          class="products-bubble"
          v-if="isLoading"
          :durationList="durationList"
          :branchIds="branchIds"
          :productList="getProductListByBranch"
          :isHasBranchNull="isHasBranchNull"
          :startMonthProps="startMonth"
          :yearList="yearList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import DashboardFilter from '@/components/products/multiple-filter/index.vue';
import ProductsByPeriod from '@/views/products/dashboard/products-by-period/index.vue';
import ProductsByBubble from '@/views/products/dashboard/products-by-bubble/index.vue';
import ProductsWithHighEmissions from '@/views/products/dashboard/products-with-high-emissions/index.vue';
import { getDurations } from '@/api/duration';
import { getListProductApproved } from '@/api/product';
import moment from 'moment';
import { calcYearFromStartMonthCustome } from '@/utils/registerData';
import { getStartMonth } from '@/api/duration';
export default {
  components: {
    PageTitle,
    DashboardFilter,
    ProductsByPeriod,
    ProductsByBubble,
    ProductsWithHighEmissions,
  },
  data() {
    return {
      items: [
        {
          text: 'ダッシュボード',
          disabled: true,
          href: ROUTES.PRODUCTS,
        },
      ],
      durationList: [],
      productList: [],
      isLoading: false,
      branchIds: [],
      isHasBranchNull: false,
      yearList: [],
      startMonth: null,
    };
  },
  async mounted() {
    this.isLoading = false;
    const items = [
      {
        text: this.$t('product_dashboard_main.label_home'),
        disabled: true,
        href: ROUTES.PRODUCTS,
      },
    ];
    this.updateBreadCrumb(items);
    await Promise.all([this.handleGetStartMonth(), this.getListProductApprovedApi()]);
    this.isLoading = true;
  },
  computed: {
    ...mapState('userData', ['currentUser']),
    getProductListByBranch() {
      if (this.isHasBranchNull) {
        //case select branch null
        let productByBranch = null;
        if (this.branchIds.length <= 0) {
          // case dont select any branch
          productByBranch = this.productList.filter((product) => !product?.branch_id);
        } else {
          // has select some branch
          productByBranch = this.productList.filter(
            (product) => this.branchIds.includes(product?.branch_id) || product?.branch_id === null,
          );
          const checkBoxAll = {
            hasCheckbox: true,
            text: this.$t('b_export_report.checkbox_select_all'),
          };
          productByBranch.unshift(checkBoxAll);
        }
        return productByBranch;
      } else {
        // case don't select branch null
        let productByBranch = null;
        if (this.branchIds.length > 0) {
          productByBranch = this.productList.filter(
            (product) => product?.branch_id && this.branchIds.includes(product?.branch_id),
          );
          const checkBoxAll = {
            hasCheckbox: true,
            text: this.$t('b_export_report.checkbox_select_all'),
          };
          productByBranch.unshift(checkBoxAll);
          return productByBranch;
        } else {
          return this.productList;
        }
      }
    },
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    async getListDurations() {
      await getDurations({ contractor_id: this.currentUser.user.contractor_id }).then((res) => {
        res.data.forEach((item) => {
          let duration = {
            ...item,
            value: item.id,
            name: `${moment(item.start_at).format('YYYY/M')} - ${moment(item.end_at).format('YYYY/M')}`,
            text: `${moment(item.start_at).format('YYYY/M')} - ${moment(item.end_at).format('YYYY/M')}`,
          };
          this.durationList.push(duration);
        });
      });
    },
    async getListProductApprovedApi() {
      await getListProductApproved().then((res) => {
        this.productList = res.data.map((item) => {
          return {
            ...item,
            hasCheckbox: true,
            text: `${item.name}/${item.lot_number || ''}`,
          };
        });
        const checkBoxAll = {
          hasCheckbox: true,
          text: this.$t('b_export_report.checkbox_select_all'),
        };
        this.productList.unshift(checkBoxAll);
      });
    },
    handlerUpdateBranchIds(value, isHasBranchNull) {
      this.branchIds = value;
      this.isHasBranchNull = isHasBranchNull;
    },
    async handleGetStartMonth() {
      try {
        const data = await getStartMonth(this.$store.state.userData.contractor);
        this.startMonth = data?.data?.start_month;
        this.yearList = calcYearFromStartMonthCustome(this.startMonth, 2017);
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.emission-by-period {
  .period-header {
    margin-top: 40px;
  }
  background: $monoWhite;
  border-radius: 8px;
  .product-period {
    padding: 16px;
  }
}
.emission-by-category {
  margin-top: 48px;
  background: $monoWhite;
  border-radius: 8px;
  .products-high-emissions {
    padding: 16px;
  }
}
.emission-by-bubble {
  .products-bubble {
    padding: 16px;
  }
}

.mt-header-title {
  margin-top: 76px;
}

.mb-20-px {
  margin-bottom: 20px;
}

.mr-md-40-px {
  margin-right: 0;
}

.emission-category {
  display: flex;
  justify-content: center;
}

.dashboard-home {
  margin-left: 24px;
}

.dashboard-filter {
  left: 0;
  position: fixed;
  top: 84px;
  z-index: 99;
  width: 100%;
  padding-left: 20px;
  background: $monoOffWhite;
  border-top: 1px solid rgba(42, 42, 48, 0.1);
  .fix-top {
    border-top: none !important;
  }
}

@include desktop {
  .mt-header-title {
    margin: 24px 0px;
    padding-left: 24px !important;
  }
  .emission-by-period {
    .period-header {
      margin-top: 48px;
    }
  }
  .mr-md-40-px {
    margin-right: 40px;
  }
  .dashboard-filter {
    left: unset;
    padding-left: 40px;
    border-top: unset;
  }
}

// style for emission-by-period chart
::v-deep .emission-by-period .product-period {
  .product-title {
    margin-top: 0px;
  }
  .product-filter {
    margin: 16px 0px !important;
    gap: 16px;
    .product-filter_select {
      width: 100%;
    }
  }
  .chart__wrapper .chart__body .chart__body--left {
    border-bottom: none;
  }
  .chart__wrapper .chart__body .chart__body--center {
    padding-left: 8px;
  }
  .product-peried-chart {
    margin-top: 0px !important;
    .line-chart-year,
    .line-chart-month {
      .wj-flexchart {
        .wj-axis-y {
          .wj-label {
            fill: $goldMid !important;
          }
          .unit {
            fill: $monoMid !important;
          }
        }
      }
    }
    .month-chart {
      .line-chart-actions-bottom {
        .btn-prev,
        .btn-next {
          bottom: 30px;
        }
      }
    }
    .chart-line-year {
      .line-chart-actions-bottom {
        .btn-prev,
        .btn-next {
          bottom: 22px;
        }
      }
    }
  }
}
// style for high-emission chart
::v-deep .emission-by-category .products-high-emissions {
  .tabs-header-category {
    height: 38px;
  }
  .product-title {
    margin-top: 0px;
  }
  .tabs-header {
    margin-top: 16px !important;
    min-height: 24px;
  }
  .tab-year-select {
    .v-input {
      height: 38px;
    }
  }
  .v-input__control {
    height: 30px;
  }
  .highEmission-wrapper {
    .chart-item {
      min-height: 70px;
    }
  }
}
// style for emission-by-bubble chart
::v-deep .emission-by-bubble .products-bubble {
  .product-title {
    margin-top: 0px;
  }
  .product-filter {
    margin: 16px 0px !important;
    gap: 16px;
    .product-filter_select {
      max-width: 100%;
    }
  }
  .tabs-header {
    height: 38px;
  }
}
//style for mobile
@media (max-width: 480px) {
  .dashboard-home {
    margin-left: 0px;
    .emission-by-category {
      .products-high-emissions {
        padding: 8px;
        .product-title {
          padding: 0 20px !important;
        }
      }
    }
  }
  ::v-deep .emission-by-category {
    .product-title {
      padding: 0 20px !important;
    }
    .tabs-header {
      padding: 0 20px;
      .v-tabs-bar__content {
        .v-tab {
          width: 50% !important;
          max-width: 50% !important;
          min-width: 50% !important;
        }
        .v-tabs-slider-wrapper {
          width: 50% !important;
          max-width: 50% !important;
          min-width: 50% !important;
        }
      }
    }
    .tab-year-select {
      padding: 0 20px;
      .v-select {
        height: 40px !important;
        margin-bottom: 16px !important;
      }
    }
  }
  ::v-deep .chart-highEmission {
    margin-inline: 0px;
    .chart-detail {
      .item-wrapper {
        .item-name {
          width: 100%;
          min-height: 36px;
          .item-title {
            display: flex !important;
            flex-direction: unset;
            gap: 0px;
            align-items: baseline;
            .item-id {
              height: 20px;
            }
            .item-name {
              .product-title {
                padding: 0px 0px 0px 8px !important;
              }
            }
          }
        }
        .number-item {
          margin-top: 8px;
          .item-porduction {
            min-height: 44px !important;
          }
        }
        .description {
          .chart-detail-new-dashboard {
            min-height: 54px;
            .emissions_wrapper {
              align-items: baseline;
            }
          }
        }
      }
      .item-detail {
        .detail-chart {
          padding: 16px 20px !important;
          .table-chart {
            .ui-chart {
              .chart__wrapper {
                padding: 16px 16px;
                .row {
                  width: 100%;
                  .donut-chart {
                    .wj-flexpie {
                      margin: auto;
                    }
                  }
                  .data-table-warpper {
                    padding-left: 0px !important;
                    .data-table {
                      width: 100% !important;
                      .data-table__header {
                        th {
                          text-align: center;
                        }
                        th:first-child,
                        th:last-child {
                          padding-left: 0px;
                        }
                      }
                      .data-table__body {
                        margin-top: 4px;
                      }
                      .data-table__body {
                        td:not(:first-child):not(:last-child) {
                          width: 98px;
                          padding-right: 16px;
                          text-align: left;
                          div {
                            span {
                              display: block;
                            }
                            span:last-child {
                              padding-left: 0px;
                            }
                          }
                        }
                        td:first-child {
                          padding-right: 16px;
                        }
                        td:last-child {
                          padding-left: 0px;
                        }
                        td {
                          .table-body-wrapper {
                            .title-lable {
                              width: 66px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .dashboard-home {
    margin-left: 0px;
  }
  ::v-deep .chart-highEmission {
    margin-inline: 0px;
    .item-detail {
      .detail-chart {
        padding: 16px 20px !important;
      }
    }
  }
}
@media (max-width: 1024px) {
  .dashboard-home {
    margin-left: 0px;
  }
  ::v-deep .chart-highEmission {
    margin-inline: 0px;
    .item-detail {
      .detail-chart {
        padding: 16px 20px !important;
      }
    }
  }
}
@media (max-width: 1365px) {
  .dashboard-home {
    margin-left: 0px;
  }
  ::v-deep .chart-highEmission {
    margin-inline: 0px;
    .item-detail {
      .detail-chart {
        padding: 16px 20px !important;
      }
    }
  }
}
</style>
